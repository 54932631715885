<template>
  <mf-dialog v-model="dialog" :title="'Dispositivos do Usuário - ' + nameUser" max-width="650" persistent>
    <v-container>
      <v-card-title class="pb-0">Dispositivos</v-card-title>
      <v-carousel v-if="devices" height="400" hide-delimiter-background :show-arrows="false" :light="!darkMode">
        <v-carousel-item v-for="device in devices" :key="device.index" class="mx-auto">
          <v-list>
            <v-row no-gutters>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Status:</v-list-item-title>
                    <v-list-item-subtitle class="d-flex">
                      <v-icon class="mr-1 mt-1" x-small :color="activeFingerprint === device.fingerprint ? 'success' : 'error'">mdi-brightness-1</v-icon>
                      <span class="mt-1">
                        {{ activeFingerprint === device.fingerprint ? 'Ativo' : 'Inativo' }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Fingerprint:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.fingerprint }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Agente:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.userAgent }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Sistema Operacional:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.os }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Localização:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.locationCity }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>IP:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.ipAddress }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Criado em:</v-list-item-title>
                    <v-list-item-subtitle>{{ formatDate(device.createdAt) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Verificado:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.verified ? 'Sim' : 'Não' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bloqueado:</v-list-item-title>
                    <v-list-item-subtitle>{{ device.isBlocked ? 'Sim' : 'Não' }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-list>
        </v-carousel-item>
      </v-carousel>
      <v-row>
        <v-col cols="6">
          <mf-button color="error" :label="'Sair'" @click="$emit('close')"></mf-button>
        </v-col>
      </v-row>
    </v-container>
  </mf-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    nameUser: {
      type: String,
      default: ''
    },
    devices: {
      type: Array,
      default: () => []
    },
    activeFingerprint: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    user: null
  }),
  computed: {
    darkMode: {
      get() {
        return localStorage.getItem('dark_theme') === 'true'
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('DD/MM/YYYY, HH:MM:ss')
    }
  }
}
</script>
